import {
  ExportImportedParameterResponse,
  ExportJobResponse,
  ExportParameterRequest,
  ExportParameterResponse,
  ImportJobResponse,
  ImportParameterResponse,
  ListParameterImportsResponse,
  ParameterResponse,
} from '@autone/openapi-parameters';
import { encodeUrl } from '@autone/ui';
import { kebabCase } from 'lodash';

import { parametersApi } from '../apis';

export interface EnhancedParameterResponse extends ParameterResponse {
  path: string;
}

export const parametersApis = parametersApi.injectEndpoints({
  endpoints: (builder) => ({
    getParameters: builder.query<EnhancedParameterResponse[], void>({
      query: () => `/parameters`,
      transformResponse: (response: { parameters: ParameterResponse[] }) =>
        response.parameters.map((parameter) => ({
          ...parameter,
          path: kebabCase(parameter.name),
        })),
    }),
    runExport: builder.query<ExportParameterResponse, { parameterId: string }>({
      query: ({ parameterId }) => ({
        url: encodeUrl({
          url: `/parameters/{parameterId}/export`,
          variables: { parameterId },
        }),
        method: 'POST',
      }),
    }),
    runExportV2: builder.query<
      ExportParameterResponse,
      {
        parameterId: string;
        granularityLevel?: string;
        filters: ExportParameterRequest['filters'];
      }
    >({
      query: ({ parameterId, granularityLevel, filters }) => ({
        url: '/v2/parameters/export',
        method: 'POST',
        body: {
          parameter_id: parameterId,
          aggregation_type: granularityLevel ?? null,
          filters,
        },
      }),
    }),
    runImport: builder.query<ImportParameterResponse, { parameterId: string }>({
      query: ({ parameterId }) => ({
        url: encodeUrl({
          url: `/parameters/{parameterId}/import`,
          variables: { parameterId },
        }),
        method: 'POST',
      }),
    }),
    runImportV2: builder.query<
      ImportParameterResponse,
      { parameterId: string }
    >({
      query: ({ parameterId }) => ({
        url: '/v2/parameters/import',
        body: { parameter_id: parameterId },
        method: 'POST',
      }),
    }),
    getExportJob: builder.query<ExportJobResponse, { jobId: string }>({
      query: ({ jobId }) =>
        encodeUrl({
          url: `/parameters/jobs/{jobId}/export`,
          variables: { jobId },
        }),
    }),
    getImportJob: builder.query<ImportJobResponse, { jobId: string }>({
      query: ({ jobId }) =>
        encodeUrl({
          url: `/parameters/jobs/{jobId}/import`,
          variables: { jobId },
        }),
    }),
    getImports: builder.query<
      ListParameterImportsResponse,
      { parameterId: string }
    >({
      providesTags: ['GetImports'],
      query: ({ parameterId }) =>
        encodeUrl({
          url: `/parameters/{parameterId}/import`,
          variables: { parameterId },
        }),
    }),
    getExportImportedParameter: builder.query<
      ExportImportedParameterResponse,
      { parameterId: string; importJobId: string }
    >({
      query: ({ parameterId, importJobId }) =>
        encodeUrl({
          url: `/parameters/{parameterId}/import/{importJobId}/export`,
          variables: { parameterId, importJobId },
        }),
    }),
  }),
});

export const {
  useGetParametersQuery,
  useLazyRunExportQuery,
  useLazyRunExportV2Query,
  useGetExportJobQuery,
  useLazyRunImportQuery,
  useGetImportJobQuery,
  useLazyGetParametersQuery,
  useGetImportsQuery,
  useRunImportV2Query,
  useLazyRunImportV2Query,
  useLazyGetExportImportedParameterQuery,
} = parametersApis;
