import { customBaseQuery, isFeatureSwitchEnabled } from '@autone/ui';
import { createApi } from '@reduxjs/toolkit/query/react';

// Define a service using the base core URL and expected endpoints
export const parametersApi = createApi({
  reducerPath: 'parametersApi',
  tagTypes: ['GetImports'],
  baseQuery: async (args, api, extraOptions) => {
    // custom query adds auth headers and error handling
    const isV2Enabled = await isFeatureSwitchEnabled({
      key: 'parameters-api-v2',
      defaultValue: true,
    });
    const uri = isV2Enabled ? 'v2/parameters' : 'parameters';
    return customBaseQuery(args, api, extraOptions, uri);
  },
  endpoints: () => ({}),
});
