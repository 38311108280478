import { ProductDropdown } from '@autone/ui';

export const PUBLIC_URL = process.env.PUBLIC_URL || '';
export const APP_NAME = 'hub';
export const PARAMETERS_HEIGHT = 'calc(100vh - 84px)';
export const PRODUCT_DROPDOWN_FIELDS: ProductDropdown[] = [
  'subDepartments',
  'seasons',
  'events',
  'brands',
  'products',
];
